html,
body {
  min-height: 100vh;
  /* overflow: auto; */
}

/*sub title*/
@font-face {
  font-family: sub-title;
  src: url('./OverCock/overlock-bold-webfont.woff');
}

/*title*/
@font-face {
  font-family: title;
  src: url('./OverCock/overlock-black-webfont.woff');
}

/*text*/
@font-face {
  font-family: text;
  src: url('./OverCock/overlock-regular-webfont.woff');
}
